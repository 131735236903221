let SMPNum = require("../../../src/SMPNum");

class BalanceKpiUtils  {
    constructor (gameDataService) {
        this.gameDataService = gameDataService;
    }

    getTimeToKillGhostInSMPNum (ghostLv,heroLv,hitPerSeconds) {
        let smpNumHpGhost = this.gameDataService.getGhostHP(ghostLv);
        let dmg = this.gameDataService.getHeroDMG(heroLv);
        let hits = SMPNum.divSmpNum(smpNumHpGhost,dmg);
        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one,hits)) {
            hits = one;
        }
        let seconds = SMPNum.divSmpNum(hits,new SMPNum(parseInt(hitPerSeconds)));
        return seconds;
    }

    getTimeToKillBoss (bossLv, heroLv, hitPerSeconds) {
        let smpNumHpBoss = this.gameDataService.getBossHP(bossLv);
        let dmg = this.gameDataService.getHeroDMG(heroLv);
        let hits = SMPNum.divSmpNum(smpNumHpBoss,dmg);
        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one, hits)) {
            hits = one;
        }
        let seconds = SMPNum.divSmpNum(hits, new SMPNum(parseInt(hitPerSeconds)));
        return SMPNum.ToReadableTimeValue(seconds);
    }

    getTimeToKillBossValueOnly (bossLv, heroLv, hitPerSeconds) {
        let smpNumHpBoss = this.gameDataService.getBossHP(bossLv);
        let dmg = this.gameDataService.getHeroDMG(heroLv);
        let hits = SMPNum.divSmpNum(smpNumHpBoss,dmg);
        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one, hits)) {
            hits = one;
        }
        let seconds = SMPNum.divSmpNum(hits, new SMPNum(parseInt(hitPerSeconds)));
        return seconds;
    }

    getTimeToKillGhost (ghostLv,heroLv,hitPerSeconds) {
        let smpNumHpGhost = this.gameDataService.getGhostHP(ghostLv);
        let dmg = this.gameDataService.getHeroDMG(heroLv);
        let hits = SMPNum.divSmpNum(smpNumHpGhost,dmg);
        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one,hits)) {
            hits = one;
        }
        let seconds = SMPNum.divSmpNum(hits,new SMPNum(parseInt(hitPerSeconds)));
        return SMPNum.ToReadableTimeValue(seconds);
    }

    getTimeToKillGhostByDPS (ghostLv, dps) {
        let smpNumHpGhost = this.gameDataService.getGhostHP(ghostLv);
        let seconds = SMPNum.fromNum(0);
        if(!dps.isZero){
            seconds = SMPNum.divSmpNum(smpNumHpGhost, dps);

            //at less one hit in 0.2s
            if(SMPNum.greaterThan(SMPNum.fromNum(0.2), seconds)){
                seconds.setNumDouble(0.2);
            }
        }

        return SMPNum.ToReadableTimeValue(seconds);
    }
    getTimeToKillGhostByDPSSMPNum (ghostLv, dps) {
        let smpNumHpGhost = this.gameDataService.getGhostHP(ghostLv);
        let seconds = SMPNum.fromNum(0);
        if(!dps.isZero){
            seconds = SMPNum.divSmpNum(smpNumHpGhost, dps);

            //at less one hit in 0.2s
            if(SMPNum.greaterThan(SMPNum.fromNum(0.2), seconds)){
                seconds.setNumDouble(0.2);
            }
        }

        return seconds;
    }

    getTimeToKillBossByHeroDPS (bossLv, dps) {
        return SMPNum.ToReadableTimeValue(this.getRealTimeToKillBossByHeroDPS(bossLv, dps));
    }

    getRealTimeToKillBossByHeroDPS (bossLv, dps) {
        let smpNumHpBoss = this.gameDataService.getBossHP(bossLv);
        let seconds = SMPNum.fromNum(0);
        if(!dps.isZero){
            seconds = SMPNum.divSmpNum(smpNumHpBoss, dps);

            //at less one hit in 0.2s
            if(SMPNum.greaterThan(SMPNum.fromNum(0.2), seconds)){
                seconds.setNumDouble(0.2);
            }
        }

        return seconds;
    }

    getRealTimeToKillZoneBossByHeroDPS (bossLv, dps) {
        let smpNumHpBoss = this.gameDataService.getZoneBossHP(bossLv);
        let seconds = SMPNum.fromNum(0);
        if(!dps.isZero){
            seconds = SMPNum.divSmpNum(smpNumHpBoss, dps);

            //at less one hit in 0.2s
            if(SMPNum.greaterThan(SMPNum.fromNum(0.2), seconds)){
                seconds.setNumDouble(0.2);
            }
        }

        return seconds;
    }

    getTotalHitsRequiredToKillAGhostAtLevel (ghostLv,heroLv) {
        let smpNumHpGhost = this.gameDataService.getGhostHP(ghostLv);
        let dmg = this.gameDataService.getHeroDMG(heroLv);
        let hits = SMPNum.divSmpNum(smpNumHpGhost,dmg);
        let one = new SMPNum(1);
        if (SMPNum.greaterThan(one,hits)) {
            return "1";
        }else {
            return hits.ToReadableAlphabetV2();
        }
    }

    getTimeToKillGhostReadable (level,hero,hitPerSeconds) {
        let seconds = this.getTimeToKillGhost(level, hero, hitPerSeconds);
        return SMPNum.ToReadableTimeValue(seconds);
    }

    ComputeSumGeometricForSeries(seriesName,level) {
        return this.gameDataService.mathGamePlay.ComputeSumGeometricForSeries(seriesName, level);
    }

    computeSumOfGoldForLevel(level){
        let baseDrop = this.gameDataService.mathGamePlay.ComputeSumGeometricForSeries('DropCoins', level);

        //ghost
        let ghostCountPerLv = this.gameDataService.ratiosConfig.ghostCounterPerLv;
        let g = SMPNum.multSmpNum(baseDrop, SMPNum.fromNum(ghostCountPerLv));

        //boss
        let bossCounterPerLv = this.gameDataService.ratiosConfig.bossCounterPerLv;
        let bossGoldRatio = this.gameDataService.ratiosConfig.bossGoldGhostRatio;
        let b = SMPNum.multSmpNum(baseDrop, SMPNum.fromNum(bossCounterPerLv * bossGoldRatio));

        //zone boss
        let zoneBossCounterPerLv = this.gameDataService.ratiosConfig.zoneBossCounterPerLv;
        let zoneBossGoldRatio = this.gameDataService.ratiosConfig.zoneBossGoldGhostRatio;
        let zb = SMPNum.multSmpNum(baseDrop, SMPNum.fromNum(zoneBossCounterPerLv * zoneBossGoldRatio));

        let total = SMPNum.plus(g, b);
        total = SMPNum.plus(total, zb);

        return total;
    }
}

export default BalanceKpiUtils;
