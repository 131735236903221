module.exports =  {
    THUNDER_ATTACK: 0,//HeavenlyStrike_Skill
    TWIN_SHADOW: 1,//ShadowClone_Skill
    LAST_HAND: 2,//CriticalStrike_Skill
    FAST_AND_FURIOUS: 3, //SMPSkillWarCry
    ANGER_OF_GOD: 4, //SMPBerserkerRageSkill
    GOLD_FINGER: 5,//Hand_of_Midas_Skill
    ANGER_OF_PET: 6,//AngerOfPet_Skill
    PROTECTION: 7,//Protection_Skill
    FLYING_SUPPORT: 8,//SupportFly_Skill
    PLOV_KAT: 9,
    THE_TEAMMATE: 10//Teammate_Skill_container
};
