let SMPNum = require('/src/SMPNum');
let AchievmentConstants = require('/src/constants/AchievementIdConstants');

const FIBO = [1,2,3,5,8,13,21,34,55,89,144,233,377,610,987,1597,2584,4181,6765,10946,17711,28657,46368];

class SMPGameplayAchievement {

     constructor (jsonRecord,gamePlayDataService) {

          this.gamePlayDataService = gamePlayDataService;
          /*old
          if (! gameplay) {
               this.gameplay = new SMPGameplay();
          }else {
               this.gameplay = gameplay;
          }*/

          if (jsonRecord) {
               this.id = jsonRecord.id;
               this.name = jsonRecord.name;
               this.mode = jsonRecord.mode;
               this.baseGoal = jsonRecord.baseGoal;
               this.goalMultiplier = jsonRecord.goalMultiplier;

               this.formula = this.getFormula(this.id);
               this.unit = this.getUnit(this.id);
               this.cumulation = false;
               this.iconName = jsonRecord.iconName;
               this.tierReward = jsonRecord.tierReward;


          }else {
               this.baseGoal = 1;
               this.goalMultiplier = 1;
          }

          this.startLevel = this.baseGoal?parseInt(this.baseGoal):10;
          this.jumpingLevel = this.goalMultiplier;
          this.currentLevel = this.startLevel;
          this.isLastQuest = false;
          this.setChallenge();
     }


     compareChallenge(challengeValue,achievementId) {
          if (achievementId === AchievmentConstants.COLLECT_GOLD
               ||achievementId === AchievmentConstants.REACH_HEROES_DPS
          ) {
               //expected type SMPNum
               if (SMPNum.greaterThan(challengeValue,this.challenge)) {
                    return true;
               }else {
                    return false;
               }

          }else {
               let result = challengeValue >= this.challenge;
               return  result;
          }


     }


     simulateUnlockForChallengeValue(challengeValue,achievementId) {
          if (achievementId !== AchievmentConstants.KILL_ENNEMY
               && achievementId !== AchievmentConstants.KILL_GHOST
               && achievementId !== AchievmentConstants.KILL_MINIBOSS
               && achievementId !== AchievmentConstants.UNLOCK_HEROES
               && achievementId !== AchievmentConstants.UNLOCK_SUPPORT
               && achievementId !== AchievmentConstants.COLLECT_GOLD
               && achievementId !== AchievmentConstants.REACH_HEROES_DPS
               && achievementId !== AchievmentConstants.KILL_N_BOSSES
               && achievementId !== AchievmentConstants.TAP_N_TIMES
               && achievementId !== AchievmentConstants.REACH_SUPPORT_N_LEVEL
               && achievementId !== AchievmentConstants.REACH_STAGE
               && achievementId !== AchievmentConstants.FAIRY_PRESENT
               && achievementId !== AchievmentConstants.REACH_FEAR_BUNNY_EARS
               && achievementId !== AchievmentConstants.GET_CRITICAL_HIT
          ) {
               return null;
          }

          //let totalCompletions = 100;
          let cumulatedReward = 0;

          let mapping = [];

          this.reset();
          //for (let nthComplete=0;nthComplete<totalCompletions;nthComplete++) {
          let nthComplete = 0;
          //while (challengeValue >= this.challenge && nthComplete < totalCompletions) {

          //TODO need to be able to compare the different challenge completion.
          //let challengeBigger = challengeValue >= this.challenge

          while (!this.isLastQuest && this.compareChallenge(challengeValue,achievementId) ) {



               let content = {};

               content.level = this.currentLevel;
               content.levelComplete = this.currentLevel -1;

               content.reward = this.getDiamondsForAchievementColletMoney();
               content.challenge = this.challenge;
               content.unit = this.unit;
               cumulatedReward = cumulatedReward + content.reward;
               mapping.push(content);
               //challengeValue = challengeValue - this.challenge;

               nthComplete ++;
               if (nthComplete > 0) {
                    this.collectAchievement();
               }
          }
          this.reset();
          let result = mapping.reduce((acc,sim) => (sim.reward + acc),0);
          return {
               reward: result,
               completed: mapping
          };

     }


     getOriginal() {
          let original = {};
          original.id = this.id;
          original.name = this.name;
          original.mode = this.mode;
          original.baseGoal = this.startLevel;
          original.goalMultiplier = this.jumpingLevel;
          original.iconName = this.iconName;
          if (original.mode !== 1) {
               original.tierReward = this.tierReward;
          }
          return original;
     }

     getUnit() {
          let result = 'undefined';
          switch(this.id) {
               case AchievmentConstants.COLLECT_GOLD : {
                    result = 'SMPNum';
                    break;
               }
               case AchievmentConstants.KILL_ENNEMY : {
                    result = 'Number';
                    break;
               }

               case AchievmentConstants.KILL_MINIBOSS:
               case AchievmentConstants.KILL_GHOST: {
                    result = 'Number';
                    break;
               }

               case AchievmentConstants.KILL_N_BOSSES : {
                    result = 'Number';
                    break;
               }

               case AchievmentConstants.TAP_N_TIMES: {
                    result = 'Minutes';
                    break;
               }

               case AchievmentConstants.REACH_HEROES_DPS: {
                    result = 'SMPNum';
                    break;
               }
               case AchievmentConstants.REACH_SUPPORT_N_LEVEL: {
                    result = 'Support Level';
                    break;
               }
               case AchievmentConstants.FAIRY_PRESENT: {
                    result = 'Fairy Present';
                    break;
               }
               case AchievmentConstants.USE_THUNDER : {
                    result = 'MANA Perk Count'
                    break;
               }
               case AchievmentConstants.REACH_STAGE: {
                    result = 'Complete a stage';
                    break;
               }
               case AchievmentConstants.GET_CRITICAL_HIT: {
                    result = 'Count. Critical Hit';
                    break;
               }
               case AchievmentConstants.USE_PERK_DOOM: {
                    result = 'DIAMOND: Count. Perk Usage';
                    break;
               }
               case AchievmentConstants.USE_MANA_PORTION: {
                    result = 'DIAMOND: MANA PORTION';
                    break;
               }
               case AchievmentConstants.USE_POWER_OF_HOLDING: {
                    result = 'DIAMOND: POWER OF HOLDING';
                    break;
               }
               case AchievmentConstants.USE_GIVE_ME_CASH: {
                    result = 'DIAMOND: GIVE ME CASH';
                    break;
               }

               case AchievmentConstants.UNLOCK_SUPPORT: {
                    result  = 'Count. Unlock support';
                    break;
               }

               case AchievmentConstants.REACH_FEAR_BUNNY_EARS: {
                    result = 'Skill level';
                    break;
               }

               default: {
                    result = 'undefined';
                    break;
               }
          }
          return result;
     }



     setChallenge() {
          ////console.log('SET CHALLENGE ' + this.id);
          switch(this.id) {
               case AchievmentConstants.COLLECT_GOLD : {
                    this.setGoldChallenge();
                    break;
               }
               case AchievmentConstants.KILL_ENNEMY : {
                    this.challenge = 200 * parseInt(this.currentLevel);
                    break;
               }

               case AchievmentConstants.KILL_MINIBOSS : {
                    this.challenge =   2 * parseInt(this.currentLevel);
                    break;
               }

               case AchievmentConstants.KILL_GHOST : {
                    this.challenge = 200 * parseInt(this.currentLevel);
                    break;
               }

               case AchievmentConstants.KILL_N_BOSSES : {
                    this.challenge = parseInt(this.currentLevel);
                    break;
               }

               case AchievmentConstants.UNLOCK_HEROES : {
                    this.challenge = parseInt(this.currentLevel);
                    if (this.challenge >= 9) {
                         this.challenge = 9;
                    }
                    break;
               }

               case AchievmentConstants.UNLOCK_SUPPORT : {
                    this.challenge = parseInt(this.currentLevel);
                    if (this.challenge >= 21) {
                         this.challenge = 21;

                    }
                    break;
               }

               case AchievmentConstants.TAP_N_TIMES: {
                    // let averageTap = 30;
                    // let wave = 10;
                    // let farmingCoefficient = 20;
                    // this.challenge = averageTap * wave * farmingCoefficient;


                    this.challenge = 300 * parseInt(this.currentLevel) * 60;
                    ////console.log('DID SET TAP N TIMES');

                    break;
               }

               case AchievmentConstants.REACH_HEROES_DPS: {

                    let challengeNum = this.gamePlayDataService.getBossHP(this.currentLevel);

                    challengeNum.setPower(Math.ceil(challengeNum._power));
                    challengeNum = SMPNum.divSmpNum(challengeNum,new SMPNum(2));

                    this.challenge = challengeNum;

                    break;
               }

               case AchievmentConstants.REACH_SUPPORT_N_LEVEL: {
                    this.challenge = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    break;
               }

               case AchievmentConstants.FAIRY_PRESENT: {
                    this.challenge = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    break;
               }
               case AchievmentConstants.USE_THUNDER: {
                    this.challenge = FIBO[parseInt(this.currentLevel)];
                    break;
               }

               case AchievmentConstants.REACH_STAGE: {
                    this.challenge = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    break;
               }
               case AchievmentConstants.GET_CRITICAL_HIT: {
                    this.challenge = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    break;
               }
               case AchievmentConstants.USE_PERK_DOOM: {
                    //this.challenge = FIBO[parseInt(this.currentLevel)];
                    this.challenge = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    break;
               }

               case AchievmentConstants.USE_GIVE_ME_CASH: {
                    this.challenge = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    break;
               }

               case AchievmentConstants.USE_POWER_OF_HOLDING: {
                    this.challenge = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    break;
               }
               case AchievmentConstants.USE_MANA_PORTION: {
                    this.challenge = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    break;
               }

               case AchievmentConstants.REACH_FEAR_BUNNY_EARS: {
                    this.challenge = parseInt(this.currentLevel) - 1 + parseInt(this.jumpingLevel);
                    break;
               }

               default: {
                    this.challenge = 10;
                    break;
               }
          }

     }


     getFormula(idAchievement) {
          let result = 'undefined';
          switch(idAchievement) {
               case AchievmentConstants.COLLECT_GOLD : {
                    result = 'OK - Drop Coins Ghost - base goal is ghost level';
                    break;
               }
               case AchievmentConstants.KILL_ENNEMY : {
                    result = ' given mission is 100 monster, 100 * level , increase of 1 mission equivalent';
                    break;
               }
               case AchievmentConstants.REACH_HEROES_DPS : {
                    result = 'OK - HPBoss - base goal is boss level';
                    break;
               }
               case AchievmentConstants.KILL_N_BOSSES : {
                    result = 'OK Kill N Bosses - ??? similar to ennemy';
                    break;
               }
               case AchievmentConstants.TAP_N_TIMES : {
                    result = 'Given 300 taps/minute - Challenge == 1 hour of tap. Increase of 1hours. 60 * 300 * level- (challenge completion) '; //si l'average est 20 tap par monstre, 10 wave,
                    // on multiplie le ghost level par láverage moyen de tap 20 * level monster * 10 * farmingCoefficient
                    break;
               }

               case AchievmentConstants.REACH_SUPPORT_N_LEVEL : {
                    result = 'Linear formula, every 100 levels';
                    break;
               }

               case AchievmentConstants.USE_THUNDER : {
                    //result = 'Fibonacci suite';
                    result = 'Linear, based on jumping level';
                    break;

               }

               case AchievmentConstants.REACH_STAGE : {
                    result = 'Linear, based on jumping level.';
                    break;
               }

               case AchievmentConstants.GET_CRITICAL_HIT : {
                    result = 'Linear, based on jumping level.';
                    break;
               }

               case AchievmentConstants.USE_PERK_DOOM : {
                    result = 'OK - (shot/doom) Linear (dont impact source diamond)';
                    break;
               }

               case AchievmentConstants.KILL_GHOST : {
                    result = 'OK Linear';
                    break;
               }

               case AchievmentConstants.KILL_MINIBOSS : {
                    result = 'OK Linear';
                    break;
               }

               case AchievmentConstants.FAIRY_PRESENT : {
                    result = 'Linear, medium increase - does not cumulate';
                    break;
               }

               case AchievmentConstants.UNLOCK_SUPPORT : {
                    result = "OK Linear and hard defined";
                    break;
               }

               case AchievmentConstants.UNLOCK_HEROES : {
                    result = "OK Linear and hard defined";
                    break;
               }

               case AchievmentConstants.USE_GIVE_ME_CASH:
                    result = 'OK - dont count in source of diamond balance';
                    break;

               case AchievmentConstants.USE_POWER_OF_HOLDING: {
                    result = 'OK - dont count in source of diamond balance';
                    break;
               }

               case AchievmentConstants.USE_MANA_PORTION: {
                    result = 'OK - dont count in source of diamond balance';
                    break;
               }

               case AchievmentConstants.REACH_FEAR_BUNNY_EARS : {
                    result = 'Linear, based on jumping level.';
                    break;
               }

               default: {
                    result = "undefined";
               }
          }
          return result;
     }


     reset() {
          this.startLevel = this.baseGoal;
          this.currentLevel = this.startLevel;
          this.setChallenge();
     }

     getDiamondsForAchievementColletMoney() {

          if  (this.currentLevel === this.startLevel) {
               if (this.id === AchievmentConstants.UNLOCK_HEROES) {
                    return 5;
               }
               if (this.id === AchievmentConstants.USE_GIVE_ME_CASH) {
                    return 100;
               }

               if (this.id === AchievmentConstants.USE_POWER_OF_HOLDING) {
                    return 50;
               }

               if (this.id === AchievmentConstants.USE_PERK_DOOM) {
                    return 150;
               }

               if (this.id === AchievmentConstants.USE_MANA_PORTION) {
                    return 1;
               }

               return 1;
          }else {

               let diamonds = 0;
               switch(this.id) {

                    case AchievmentConstants.UNLOCK_HEROES :
                         diamonds = 5;
                         break;

                    case AchievmentConstants.UNLOCK_SUPPORT:
                         diamonds = 3;
                         break;

                    case AchievmentConstants.KILL_MINIBOSS:
                         diamonds = 1 + Math.round(this.currentLevel  / this.jumpingLevel);
                         if (diamonds > 10) {
                              diamonds = 10;
                         }

                         if (this.currentLevel < 500) {
                              diamonds = 1;
                         }
                         else if (this.currentLevel >= 500 && this.currentLevel < 1000) {
                              diamonds = 2;
                         } else if (this.currentLevel >= 1000 && this.currentLevel < 5000) {
                              diamonds = 3;

                         } else if (this.currentLevel >= 5000 && this.currentLevel < 10000) {
                              diamonds = 4;
                         } else if (this.currentLevel >= 10000 && this.currentLevel < 20000) {
                              diamonds = 5;
                         }else {
                              diamonds = 10;
                         }

                         break;

                    case AchievmentConstants.KILL_GHOST:
                         diamonds = 1 + Math.round((this.currentLevel- this.baseGoal) / this.jumpingLevel);
                         if (diamonds > 10) {
                              diamonds = 10;
                         }
                         break;

                    case AchievmentConstants.COLLECT_GOLD :
                         diamonds = Math.ceil((this.currentLevel) / this.jumpingLevel);
                         if (diamonds > 10) {
                              diamonds = 10;
                         }
                         break;

                    case AchievmentConstants.REACH_HEROES_DPS:
                         diamonds = Math.ceil((this.currentLevel) / this.jumpingLevel);
                         if (diamonds > 10) {
                              diamonds = 10;
                         }
                         break;

                    case AchievmentConstants.KILL_N_BOSSES:
                         // diamonds = Math.ceil((this.currentLevel) / this.jumpingLevel);
                         // if (diamonds > 10) {
                         //     diamonds = (this.currentLevel / 10) - 10;
                         // }
                         diamonds = Math.ceil((this.currentLevel / this.jumpingLevel) % this.jumpingLevel);
                         if (diamonds === 0 || diamonds > 10) {
                              diamonds = 10;
                         }
                         //diamonds = diamonds - (this.currentLevel / 10)
                         //diamonds = 1;
                         break;

                    case AchievmentConstants.USE_GIVE_ME_CASH:
                         //diamonds = Math.ceil((this.currentLevel / this.jumpingLevel)) * 100;
                         diamonds = 100;
                         break;

                    case AchievmentConstants.USE_POWER_OF_HOLDING:
                         //diamonds = Math.ceil((this.currentLevel / this.jumpingLevel)) * 100;
                         diamonds = 50;
                         break;

                    case AchievmentConstants.USE_MANA_PORTION:
                         //diamonds = Math.ceil((this.currentLevel / this.jumpingLevel)) * 100;
                         diamonds = 1;
                         break;

                    case AchievmentConstants.USE_PERK_DOOM:
                         //diamonds = Math.ceil((this.currentLevel / this.jumpingLevel)) * 100;
                         diamonds = 150;
                         break;

                    case AchievmentConstants.TAP_N_TIMES:
                         diamonds = Math.round( this.currentLevel / this.jumpingLevel);
                         if (diamonds > 10)
                         {
                              diamonds = 10;
                         }
                         break;

                    case AchievmentConstants.FAIRY_PRESENT:
                         diamonds = Math.ceil( this.currentLevel / this.jumpingLevel);
                         if (diamonds > 10)
                         {
                              diamonds = 10;
                         }
                         break;

                    case AchievmentConstants.REACH_FEAR_BUNNY_EARS:
                         diamonds = Math.round( this.currentLevel / this.jumpingLevel);
                         if (diamonds > 10)
                         {
                              diamonds = 10;
                         }
                         break;

                    case AchievmentConstants.GET_CRITICAL_HIT:
                         diamonds = Math.ceil( this.currentLevel / this.jumpingLevel);
                         if (diamonds > 10)
                         {
                              diamonds = 10;
                         }
                         break;

                    default:
                         diamonds = Math.ceil( this.currentLevel / this.jumpingLevel);
                         if (diamonds > 10)
                         {
                              diamonds = 10;
                         }
                         break;
               }

               return diamonds;
          }
     }

     getNextLevel() {

          switch(this.id) {
               case AchievmentConstants.COLLECT_GOLD : {
                    return parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
               }
               case AchievmentConstants.KILL_ENNEMY : {
                    return this.currentLevel = parseInt(this.currentLevel) + 2;
               }
               case AchievmentConstants.TAP_N_TIMES : {
                    return this.currentLevel = parseInt(this.currentLevel) + 1;
               }
               case AchievmentConstants.REACH_HEROES_DPS: {
                    this.currentLevel = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    return this.currentLevel;
               }
               case AchievmentConstants.KILL_N_BOSSES : {
                    this.currentLevel = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    return this.currentLevel;
               }
               case AchievmentConstants.KILL_GHOST : {
                    this.currentLevel = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    return this.currentLevel;
               }

               case AchievmentConstants.KILL_MINIBOSS : {
                    this.currentLevel = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    return this.currentLevel;
               }

               case AchievmentConstants.USE_THUNDER : {
                    this.currentLevel = parseInt(this.currentLevel) + 1;
                    return this.currentLevel;
               }
               case AchievmentConstants.REACH_STAGE: {
                    this.currentLevel = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    return this.currentLevel;
               }

               case AchievmentConstants.UNLOCK_HEROES: {
                    this.currentLevel = parseInt(this.currentLevel) + 1;
                    if (this.currentLevel > 8) {
                         this.isLastQuest = true;
                    }
                    return this.currentLevel;
               }

               case AchievmentConstants.UNLOCK_SUPPORT: {
                    this.currentLevel = parseInt(this.currentLevel) + 1;
                    if (this.currentLevel > 20) {
                         this.isLastQuest = true;
                    }
                    return this.currentLevel;
               }

               case AchievmentConstants.REACH_FEAR_BUNNY_EARS: {
                    this.currentLevel = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
                    return this.currentLevel;
               }

               default:
                    return this.currentLevel = parseInt(this.currentLevel) + parseInt(this.jumpingLevel);
          }

     }

     collectAchievement() {
          this.currentLevel = this.getNextLevel(this.currentLevel);
          this.setChallenge();
          return this.currentLevel;
     }

     setGoldChallenge() {

          if (this.id === AchievmentConstants.TAP_N_TIMES) {
               let averageTap = 30;
               let wave = 10;
               let farmingCoefficient = 20;
               this.challenge = this.currentLevel * averageTap * wave * farmingCoefficient;
          }else {
               let challengeNum = this.gamePlayDataService.getCoinDrop(this.currentLevel);
               challengeNum = SMPNum.multSmpNum(challengeNum,new SMPNum(this.goalMultiplier));
               challengeNum.setPower(Math.ceil(challengeNum._power));
               challengeNum = SMPNum.divSmpNum(challengeNum,new SMPNum(2));

               this.challenge = challengeNum;
          }
     }


}

module.exports = SMPGameplayAchievement
