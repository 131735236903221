import PetBonusSkillType from "../constants/PetBonusSkillType";

let SMPNum = require("../../../src/SMPNum");

class PetKpiUtils {
    constructor (listPetData, gameDataService) {
        this.listPetData = listPetData;
        this.gameDataService = gameDataService;

        this.setSelectPetActive(listPetData[0]);
        this.petsJoinTop3 = [];

        this.initPetUnlockLevel();

        /*log validate with game play
        let petData = listPetData[0];
        console.log("Pet1 lv1 passiveDMG:"+this.gameDataService.getPetPassiveDMG(SMPNum.fromNum(100000), 1, petData).ToReadableAlphabetV2());
        console.log("Pet1 lv25 passiveDMG:"+this.gameDataService.getPetPassiveDMG(SMPNum.fromNum(100000), 25, petData).ToReadableAlphabetV2());
        console.log("Pet1 lv99 passiveDMG:"+this.gameDataService.getPetPassiveDMG(SMPNum.fromNum(100000), 99, petData).ToReadableAlphabetV2());*/
    }

    setSelectPetActive(petData){
        this.petSelectedData = petData;
        this.petSelectedId = petData.petID;
    }

    definePetJoinTop3(petList, isEnablePetTop3){
        this.petsJoinTop3 = [];
        if(isEnablePetTop3){
            petList.forEach(pet => {
                if(this.petsJoinTop3.length < 2){
                    if(pet.petID !== this.petSelectedId && pet.petIsUnlock){
                        this.petsJoinTop3.push(pet);
                    }
                }
            });
        }
        //console.log('pet join ',this.petsJoinTop3);
    }

    getTop3PetDatas(){
        return this.petsJoinTop3;
    }

    initPetUnlockLevel(){
        this.gameDataService.petGamePlay.SetPetUnlockLevel(this.listPetData);
    }

    getDisplayPetList(count) {
        let pets = [];
        let unlock = 0;
        this.listPetData.forEach(p => {
            p.petIsUnlock = unlock < count;
            p.possibleLevel = p.petIsUnlock ? 1 : 0;
            unlock++;
            pets.push(p);
        });

        return pets;
    }

    getUnlockedPetList(count) {
        let pets = [];
        let unlock = 0;
        this.listPetData.forEach(p => {
            if(p.petID !== this.petSelectedId && unlock < count){
                pets.push(p);
                unlock++;
            }
        });

        return pets;
    }

    isPassivePet(petID){
        return petID !== this.petSelectedId && !this.petsJoinTop3.find(petJ => petJ.petID === petID);
    }

    getPetStandardDMG(heroTapDMG, petLevel, percentControlHeroDmgSkill){
        if(heroTapDMG.isZero || petLevel === 0){
            return SMPNum.fromNum(0);
        } else {
            let petData = this.petSelectedData;
            let mainPetDMG = this.gameDataService.getPetDMG(heroTapDMG, petLevel, petData);
            let top3PetDmg = this.getTop3PetStandardDMG(heroTapDMG, percentControlHeroDmgSkill);
            return SMPNum.plus(mainPetDMG, top3PetDmg);
        }
    }

    getTop3PetStandardDMG(heroTapDMG, percentControlHeroDmgSkill){
        let dmg = new SMPNum(0);
        this.petsJoinTop3.forEach(pet => {
            let petDmg = this.gameDataService.getPetDMG(heroTapDMG, pet.possibleLevel, pet);
            petDmg = SMPNum.multSmpNum(petDmg, new SMPNum(percentControlHeroDmgSkill/100));
            dmg = SMPNum.plus(dmg, petDmg);
        });
        return dmg;
    }

    getTotalPetPassiveDMG(heroTapDMG, petList){
        //let passivePetCounter = petUnlock - 1;
        let dmg = SMPNum.fromNum(0);
        //if(passivePetCounter > 0){
            //let unlockList = this.getUnlockedPetList(passivePetCounter);
            petList.forEach(pet => {
                if(pet.petIsUnlock && this.isPassivePet(pet.petID)){
                    dmg = SMPNum.plus(dmg, this.gameDataService.getPetPassiveDMG(heroTapDMG, pet.possibleLevel, pet));
                }
            });
        //}
        return dmg;
    }

    /*never use
    getPetSkillBonus(bonusType, petPossibleLevel, petUnlock) {
        let bonusValue = 0.0;
        let unlockList = this.getUnlockedPetList(petUnlock);
        unlockList.forEach(pet => {
            if(pet.petBonusType === bonusType){
                bonusValue = bonusValue + this.gameDataService.petGamePlay.generateActiveSkillPercent(petPossibleLevel, pet);
            }
        });
        bonusValue = parseFloat(mathjs.format(bonusValue,{precision:14}));
        return bonusValue;
    }*/

    getCurrentPetActiveSkillType(){
        return this.petSelectedData.petBonusType;
    }

    getCurrentPetActiveSkillBonusValue(){
        return this.gameDataService.petGamePlay.generateActiveSkillPercent(this.petSelectedData.possibleLevel, this.petSelectedData);
    }

    getCurrentPetActivePossibleLevel(){
        return this.petSelectedData.possibleLevel;
    }

    getPetSkillBonusFromJoinPet(skillType){
        let activeBonus = 0;
        this.petsJoinTop3.forEach(pet =>{
            if(pet.petBonusType === skillType){
                activeBonus += this.gameDataService.petGamePlay.generateActiveSkillPercent(pet.possibleLevel, pet);
            }
        });
        return activeBonus;
    }

    getCurrentPetActiveSkillBonus(percentControlHeroDmgSkill) {
        let petJoinTop3 = [];
        this.petsJoinTop3.forEach(pet =>{
            let bonusValue = this.gameDataService.petGamePlay.generateActiveSkillPercent(pet.possibleLevel, pet);
            petJoinTop3.push({
                petId: pet.petID,
                petBonusType: pet.petBonusType,
                petBonusTypeName: PetBonusSkillType[pet.petBonusType],
                bonusValue: bonusValue,
                allianceBonus: bonusValue * percentControlHeroDmgSkill / 100
            });
        });

        return {
            petId: this.petSelectedId,
            petBonusType: this.getCurrentPetActiveSkillType(),
            petBonusTypeName: PetBonusSkillType[this.getCurrentPetActiveSkillType()],
            bonusValue: this.getCurrentPetActiveSkillBonusValue(this.petSelectedData.possibleLevel),
            petJoinsTop3: petJoinTop3,
        };
    }

    /*for old avg rule
    getPetTotalActiveSkillBonus(bonusType, petList) {
        let bonusValue = 0.0;
        //let unlock = 0;
        petList.forEach(pet => {
            if(pet.petIsUnlock) {
                if (pet.petBonusType === bonusType) {
                    bonusValue = bonusValue + this.gameDataService.petGamePlay.generateActiveSkillPercent(pet.possibleLevel, pet);
                }
                //unlock++;
            }
        });
        return bonusValue;
    }*/

    getPetAvgActiveSkillBonus(bonusType, petList) {
        let bonusValue = 0.0;
        let petUnlock = 0;
        petList.forEach(pet => {
            if(pet.petIsUnlock) {
                if (pet.petBonusType === bonusType) {
                    bonusValue = bonusValue + this.gameDataService.petGamePlay.generateActiveSkillPercent(pet.possibleLevel, pet);
                }
                petUnlock++;
            }
        });

        if(bonusValue > 0 && petUnlock > 0)
        {
            let avg = bonusValue / petUnlock;
            return avg;
        } else {
            return 0;
        }
    }

    getPetTotalPassiveSkillBonus(bonusType, petList) {
        //let passivePetCounter = petUnlock - 1;
        let bonusValue = 0.0;
        //let unlockList = this.getUnlockedPetList(passivePetCounter);
        petList.forEach(pet => {
            if(pet.petIsUnlock && this.isPassivePet(pet.petID) && pet.petBonusType === bonusType){
                bonusValue = bonusValue + this.gameDataService.petGamePlay.generatePassiveSkillPercent(pet.possibleLevel, pet);
            }
        });
        return bonusValue;
    }

    definePetLevel(diamond, petList){
        let maxLevel = 99;
        for(let i = 2;i<=maxLevel; i++){
            let costOnePet = this.gameDataService.getDiamondCostUpdatePetLevel(i);
            petList.forEach(pet => {
                if(pet.petIsUnlock){
                    if(costOnePet <= diamond){
                        diamond -= costOnePet;
                        pet.possibleLevel = i;
                    }
                }
            });
        }
    }

    getPossiblePetLevelOnDiamond(diamond, petCount){
        if (petCount <=0) return 0;
        let level = 1;
        let maxLevel = 99;
        let spent = 0;
        for(let i = 2;i<=maxLevel; i++){
            let costOnePet = this.gameDataService.getDiamondCostUpdatePetLevel(i);
            spent += (costOnePet * petCount);
            if(spent < diamond){
                level = i;
            } else {
                break;
            }
        }
        return level;
    }

    getPossibleDPS(heroDMG, petList, hitPerSecond, percentControlHeroDmgSkill){
        /* old balance kpi
        let petData = this.listPetData[0];
        let baseDmg = this.gameDataService.petGamePlay.generateBaseDamage(petLv, petData);
        let avgDmg = this.gameDataService.petGamePlay.computeAverageActivePetDamage(60, baseDmg, heroDMG);*/

        let petLv = this.petSelectedData.possibleLevel;

        let petDmg = this.getPetStandardDMG(heroDMG, petLv, percentControlHeroDmgSkill);
        let totalBonusPassiveDMG = this.getTotalPetPassiveDMG(heroDMG, petList);
        let avgDmg = this.gameDataService.petGamePlay.computeAverageActivePetDamage(heroDMG, petDmg, totalBonusPassiveDMG);
        return SMPNum.multSmpNum(avgDmg, new SMPNum(hitPerSecond));
    }
}

export default PetKpiUtils;
