export default[{
    "key": "0",
    "value": "Deal tap damage [xN]"
}, {
    "key": "1",
    "value": "Create a clone to attack [N] time/sec"
}, {
    "key": "2",
    "value": "Increase critical chance by [N]%"
}, {
    "key": "3",
    "value": "All supporters attack [N]% faster"
}, {
    "key": "4",
    "value": "Increase tap damage by [N]%"
}, {
    "key": "5",
    "value": "Get [N]% monster gold per tap"
}, {
    "key": "6",
    "value": "Activate pets revenge"
}, {
    "key": "7",
    "value": "Activate shield to protect hero"
}, {
    "key": "8",
    "value": "Activate supports fly"
}, {
    "key": "9",
    "value": "Decrease monsters by stage by [-N]"
}, {
    "key": "10",
    "value": "Team up with your teammate"
}]